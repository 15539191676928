<template>
  <div class="dialog-modify">
    <div class="dialog-div"> IMEI 修改</div>
    <div class="field border flex flex-between-center flex-cross-end">
      <input class = "input-dialog" type="text" name="imeiNew" placeholder="扫码或手动输入IMEI号" v-model.trim="imeiNew" maxlength="15"/>
      <div class="flex flex-cross-end" @click="onScanButtonClick">
        <img class="portrait" src="@/assets/unsplash.svg" />
      </div>
    </div>
    <div style="text-align: center">
      <button class="cancel-buttom" @click="cancelImei">取消</button>
      <button class="check-buttom" @click="updateImei">确认</button>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import wechatApi from '@/api/wechat.js'
import Camera from '@/utils/camera'
export default {
  data () {
    return {
      imeiNew: null,
      url: location.href.split('#')[0]
    }
  },
  props: {
    info: Object
  },
  computed: {
  },
  created () {
    this.imeiNew = this.info.imeiNew
    if (this.$isWx) {
      this.supportScan = true
      wechatApi.getConfig({ url: this.url }).then(res => {
          if (res) {
            wx.config({
              debug: this.$DEBUG, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.appId, // 必填，公众号的唯一标识
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonceStr, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名
              jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
            })
          }
        })
    } else {
      Camera.support().then(r => {
          this.supportScan = r
      })
    }
  },
  watch: {

  },
  methods: {
    onScanButtonClick () {
      if (this.$isWx) {
        this.onqrcode()
      } else {
        navigator.mediaDevices.enumerateDevices().then(d => {
          window.console.log(1, d)
        })
        this.$router.push({ path: '/setup/scan' })
      }
    },
    onqrcode () {
      var vue = this
      wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: function (res) {
          if (res.resultStr) {
            if (res.resultStr.startsWith('CODE_')) {
              res.resultStr = res.resultStr.split(',')[1]
            }
            vue.getImei(res.resultStr)
          }
        },
        error: function (e) {
          console.log(21, e)
        }
      })
    },
    getImei (imei) {
      if (imei) {
        this.imeiNew = imei
        // this.info.imei = imei
      //  this.updateImei()
      } else {
        alert('扫描失败，请稍后重试')
      }
    },
    updateImei () {
      if (!this.imeiNew) {
        this.$toast.show('imei不能为空')
      } else {
        var form = {
          id: this.info.id,
          tenantId: this.info.tenantId,
          sn: this.info.sn,
          imei: this.imeiNew
        }
        this.$axios.post('/h5/device/updateAndRegister', form).then(resp => {
          this.info.imei = this.imeiNew
          this.$toast.show('修改成功')
        }).catch(error => {
          this.$toast.show(error.msg)
        })
        this.$emit('choose', true)
        }
    },
    cancelImei () {
       this.$emit('choose', false)
    }
  }
}
</script>
<style scoped lang="stylus">
.dialog-modify
  width:90%;
  height:180px;
  border:1px solid #DCDFE6;
  margin: 0 auto;
  border-radius: 5px;
  background-color: white;
.dialog-div
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
.input-dialog
  border:1px solid #DCDFE6;
  height:35px;
  width: 350px;
  margin-left: 5px;
.check-buttom
  width:80px;
  height:35px;
  background:rgba(25,121,255,0.08);
  border-radius:2px;
  border-color: transparent;
  font(28,40,#1979FF);
  margin-top: 20px;
  margin-left: 15px;
.cancel-buttom
  width:80px;
  height:35px;
  background:#f2f2f2;
  border-radius:2px;
  border-color: transparent;
  margin-top: 20px;
  margin-left: 15px;
  font(28,40,#000000);
</style>
