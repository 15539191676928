<template>
  <div class="detail" :style="colorStyle">
    <div class="header flex flex-cross-start">
      <img class="portrait" src="@/assets/default.png"/>
      <div class="flex-auto">
        <div class="model">{{info.deviceModel && info.deviceModel.brandName || "--"}} {{info.deviceModel && info.deviceModel.name || "--"}}</div>
        <div>{{info.sn}}</div>
      </div>
    </div>
    <div class="block">
      <div class="block-title">设备信息</div>
      <div class="row">
        <span class="label">设备别名：</span>
        <span>{{info.deviceName || '无'}}</span>
      </div>
      <div class="row">
        <span class="label">所属位置：</span>
        <span>{{info.address && info.address || ''}}</span>
      </div>
      <div class="row">
        <span class="label">IMEi号：</span>
        <span>{{info.imei || ''}}</span>
        <span>
          <button class="modify" @click="modifyImei">修改</button>
        </span>
      </div>
      <div v-if="dialogFlag">
        <imei-dialog :info="info" @choose="onCheck">
        </imei-dialog>
      </div>
    </div>
    <div class="block">
      <div class="block-title">设备状态</div>
      <div class="row">
        <span class="label">开关状态：</span>
        <span>{{info.switchStateEnum?info.switchStateEnum.name: '--'}}</span>
      </div>
      <div class="row">
        <span class="label">在线状态：</span>
        <span>{{info.isOnline}}</span>
      </div>
      <div class="row">
        <span class="label">运行模式：</span>
        <span>{{info.modeStateEnum? info.modeStateEnum.name:'--'}}</span>
      </div>
      <div class="row">
        <span class="label">运行风速：</span>
        <span>{{info.speedStateEnum? info.speedStateEnum.name:'--'}}</span>
      </div>
      <div class="row">
        <span class="label">设定温度：</span>
        <span>{{info.temperature?info.temperature:'--'}}℃</span>
      </div>
      <div class="row">
        <span class="label">室内温度：</span>
        <span>{{info.roomTemperature?info.roomTemperature:'--'}}℃</span>
      </div>
      <div class="row">
        <span class="label">睡眠模式：</span>
        <span>{{info.manualSleepStateEnum?info.manualSleepStateEnum.name:'--'}}</span>
      </div>
      <div class="row">
        <span class="label">电加热：</span>
        <span>{{info.heatingStateEnum?info.heatingStateEnum.name:'--'}}</span>
      </div>
      <div class="row" v-if="info.sn&&info.sn.substring(3, 10) !== '8002248'">
        <span class="label">用户控制：</span>
        <span>{{(info.lockStateEnum?(info.lockStateEnum.state === 0?'可控':'不可控'):'--') || (info.powerSwitchEnum?(info.powerSwitchEnum.state === 0?'不可控':'可控'):'--')}}</span>
      </div>
      <div style="height:40px"></div>
    </div>

  </div>
</template>

<script>
import ImeiDialog from '@/components/imei-dialog'
  export default {
    name: 'deviceDetail',
    props: ['id', 'device'],
    components: { ImeiDialog },
    data () {
      return {
        colorStyle: 'background: #fff;',
        imeiFlag: false,
        dialogFlag: false,
        supportScan: false,
        deviceBaseInfo: {
        },
        info: {
          model: {
            name: '',
            brandName: ''
          }
        }
      }
    },
    created () {
      this.loadInfo()
    },
    methods: {
      onCheck (vlaue) {
        this.colorStyle = 'background:#fff;'
        this.dialogFlag = false
      },
      modifyImei () {
        this.colorStyle = 'background:#78787c;'
        this.dialogFlag = true
        // this.info.imeiNew = this.info.imei
      },
      loadInfo () {
        if (this.device || this.id) {
          if (this.device) {
            this.deviceBaseInfo = {
              deviceName: this.device.deviceName,
              address: this.device.address,
              isOnline: this.device.connection === 'online' ? '在线' : (this.device.connection === 'offline' ? '离线' : '--')
            }
          } else {
            this.$axios.get(`/h5/device/${this.id}`).then(res => {
              this.deviceBaseInfo = {
                deviceName: res.deviceName,
                address: res.address,
                isOnline: res.isOnline === 1 ? '在线' : '离线'
              }
            })
          }

          this.$axios.get(`/h5/device/airconditioner/${this.id}`).then(res => {
            this.info = {
              ...res,
              ...this.deviceBaseInfo,
              ...res.deviceInfo
            }
            if (this.info.switchStateEnum && this.info.switchStateEnum.state === 0) {
              this.info.modeStateEnum = null
              this.info.speedStateEnum = null
              this.info.temperature = null
              this.info.manualSleepStateEnum = null
              this.info.heatingStateEnum = null
            }
          })
        }
      }
    }
  }
</script>

<style scoped lang="stylus">
.inputImei
  border:1px solid #DCDFE6;
  height:48rpx;
.modify
    width:80rpx;
    height:48rpx;
    background:rgba(25,121,255,0.08);
    border-radius:24rpx;
    float:right;
    border-color: transparent;
    font(24,40,#1979FF)
.detail
  background #fff
  padding 32rpx
  .header
    padding-bottom 32rpx
    border-bottom 1rpx solid rgba(31,31,52,.3)
    font(28,44,$subtext)
    .portrait
      box(144,144)
      margin-right 32rpx
    .model
      font(32,48,$text)
  .block
    border-bottom 1rpx solid rgba(31,31,52,.3)
    padding 32rpx 0
    .block-title
      font(32,48,$text)
    .row
      margin: 16rpx 0
      font(28,44,$text)
      word-break: break-all
      .label
        display inline-block
        width 150rpx
        margin-right 32rpx
        color $subtext
.check-buttom
  width:80px;
  height:35px;
  background:rgba(25,121,255,0.08);
  border-radius:2px;
  border-color: transparent;
  font(28,40,#1979FF);
  margin-top: 20px;
  margin-left: 15px;
.cancel-buttom
  width:80px;
  height:35px;
  background:#f2f2f2;
  border-radius:2px;
  border-color: transparent;
  margin-top: 20px;
  margin-left: 15px;
  font(28,40,#000000);
.dialog-modify
  width:90%;
  height:180px;
  border:1px solid #DCDFE6;
  margin: 0 auto;
  border-radius: 5px;
.dialog-div
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
.input-dialog
  border:1px solid #DCDFE6;
  height:35px;
  width: 350px;
  margin-left: 5px;
</style>
